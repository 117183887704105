.fz-form-control-container {
  position: relative;
}
.fz-form-control-container .fz-overlay-size {
  position: absolute;
  right: 10px;
  bottom: 0;
  font-size: 80%;
}

.fz-jumbotron {
  padding: 10px;
  border-radius: 10px;
  color: inherit;
  background-color: #f2eeeb;
}

label.control-label {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

.fz-radio-container {
  display: flex;
  margin-top: 1rem;
  border-bottom: 1px solid #ddd;
}
.fz-radio ~ label {
  display: flex;
  align-self: flex-end;
  padding-bottom: 3px;
  padding-left: 10px;
}
.fz-radio {
  display: flex;
  align-self: flex-start;
}

.fz_date_picker.react-date-picker  {
  display: inline;
}

.fz-textarea-whatsApp aside.emoji-picker-react {
  width: 100%;
}


.fz-highlightFields {
  background-color: #72E1CD !important
}

.fz-highlightFields input.form-control {
  background-color: #72E1CD
}

.fz-highlightFields select.form-control {
  background-color: #72E1CD
}

.fz-highlightFields .react-date-picker  {
  background-color: #72E1CD
}

.gap-1{
  gap: 1rem
}

