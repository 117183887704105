.screening-edit-bid {
  padding-top: 15px;
  padding-bottom: 15px;
}

.screening-edit-bid .form-group {
  margin-bottom: 4px;
  display: flex;
  align-items: baseline;
}

.screening-edit-bid .form-group .form-label {
  display: inline-block;
  font-family: 'Roboto';
  font-weight: bold;
  white-space: nowrap;
}

.screening-edit-bid div,
.screening-edit-bid div input,
.screening-edit-bid .form-control {
  display: inline-block;
  border: none;
  border-radius: 3px;
  color: #212529;
}

.screening-edit-bid .form-control:read-only,
.screening-edit-bid input:disabled {
  background: none;
}

.screening-edit-bid .form-control,
.screening-edit-bid .form-control:not(:read-only):focus {
  background: #e4e4e4;
  height: auto;
  padding: 2px 6px;
  margin-left: 2px;
}

.screening-edit-bid textarea:focus,
.screening-edit-bid textarea.form-control:focus,
.screening-edit-bid input.form-control:focus,
.screening-edit-bid input[type='text']:focus,
.screening-edit-bid input[type='password']:focus,
.screening-edit-bid input[type='email']:focus,
.screening-edit-bid input[type='number']:focus,
.screening-edit-bid [type='text'].form-control:focus,
.screening-edit-bid [type='password'].form-control:focus,
.screening-edit-bid [type='email'].form-control:focus,
.screening-edit-bid [type='tel'].form-control:focus,
.screening-edit-bid [contenteditable].form-control:focus {
  box-shadow: unset;
}

.screening-button {
  padding: 5px 20px;
  border-radius: 5px;
  font-size: 15px;
  border: none;
  font-weight: bold;
}

.edit-button {
  background-color: #efefef;
  border: 1px solid #ced4da;
}

.save-button {
  background-color: #05bcb4;
  color: #fff;
}

.save-button:disabled {
  opacity: 0.3;
}

.cancel-button {
  margin-left: 10px;
  background-color: #fff;
  border: 1px solid #ced4da;
}

.confirm-modal-bg {
  background-color: rgba(0, 0, 0, 0.35);
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 10;
}
