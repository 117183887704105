.modal-form {
  width: 80%;
}

.alert-line {
  margin-top: 10px;
}

.row-striped:nth-of-type(even) {
  background-color: #f1f1f1;
}

.row {
  padding-top: 3px;
  padding-bottom: 2px;
  vertical-align: middle;
}

.foot-note {
  color: gray;
}

.form-group {
  margin-bottom: 7px;
}

.form-label {
  display: inline-block;
  position: relative;
  padding-right: 25px;
}

.form-label i {
  position: absolute;
  right: 0;
  top: calc(50% - 9px);
  background-color: rgb(89, 35, 221);
  border-radius: 50%;
  height: 18px;
  width: 18px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  font-weight: 600;
  font-style: normal;
  z-index: 100;
}

.form-label i:hover {
  height: max-content;
  width: max-content;
  border-radius: 5px;
  padding: 5px;
  transform: translateX(calc(100% - 18px));
  max-width: 350px;
  z-index: 101;
}

.form-label i:hover span {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 10px;
  border-radius: 50%;
  height: 18px;
  width: 18px;
  min-width: 18px;
  background-color: #fff;
  color: rgb(89, 35, 221);
}

.form-label i p {
  display: none;
  white-space: break-spaces;
}

.form-label i:hover p {
  display: block;
}

.form-control-static {
  padding-top: 1px;
}
.dimmed {
  color: #999;
}

label {
  margin-bottom: 2px;
}
.bottom-toolbar {
  padding-top: 10px;
  padding-bottom: 5px;
}
.radio-inline {
  padding-left: 3px;
}
.checks-inline {
  display: inline-block;
  padding-right: 10px;
  margin-left: 5px;
}
.check-input {
  margin-left: 10px !important;
}
.vertical-button-list {
  padding-right: 15px;
}

.btn-group {
  padding-bottom: 5px;
}

.btn-group .label {
  line-height: 2;
}

.decoratedHeader .label {
  margin-left: 2px;
  margin-right: 2px;
}
.form-control-static .label {
  margin-right: 2px;
}
.clickable {
  cursor: pointer;
  padding: 5px;
}

.react-bs-table .table-bordered th {
  font-size: 80%;
}
.inner-table {
  margin-left: 60px;
  font-size: 90%;
  max-width: 960px;
}
table.inner-table {
  margin-left: 8%;
  width: 92%;
}
table.inner-table > thead > tr > th {
  padding: 3px;
  background-color: blanchedalmond;
}
table.inner-table > tbody > tr > td {
  padding: 3px;
  background-color: #fffbdd;
}
