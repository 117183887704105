.btn-group-queues,
.btn-actions {
  display: flex;
}

.btn-actions {
  width: 100%;
}

.btn-actions > * {
  margin-right: 10px;
}

.mr-10 {
  margin-right: 10px;
}