.fz-splitline {
  display: flex;
  align-items: center;
}

.fz-splitline.fz-up {
  align-items: flex-start;
}

.fz-splitline.fz-down {
  align-items: flex-end;
}

.fz-splitline-left {
  flex-shrink: 1;
  flex-grow: 1;
}

.fz-splitline-right {
  flex-shrink: 0;
  flex-grow: 0;
  padding-left: 0.5em;
}

.fz-remarks {
  color:#888;
  font-size: 70%;
}
.panel .fz-discrete .panel-title {
  font-size: 90%;
  transition: 1s ease-in all;
}

.panel.collapsed {
  height: 0;
}

.panel .fz-nested  {
  margin-bottom: 10px;
}
.panel .fz-discrete .panel-heading {
  padding-bottom: 5px;
  padding-top: 5px;
}
.label-default.fz-discrete {
  background-color: #bbb;
}
.label ~ .label {
  margin-left:3px
}


.nvselect .col-sm-2 {
  padding: 2px;
  width: auto;
}