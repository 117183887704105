.fz-panel {
  display: inline-flex;
  flex-wrap: wrap;
}

.fz-panel-border-padding {
  border-right: 3px solid #dedede;
  padding: 1rem;
}



@media (max-width: 780px) {
  .fz-panel {
    display: block ;
  }

  .fz-panel-border-padding {
    border-bottom: 3px solid #dedede;
  }

  .fz_date_picker.react-date-picker {
    display: grid;
  }

  .react-calendar {
    position: relative;
    top: 200px;
    right: -13px;
  }

  .fz-panel-integration {
    width: 110px;
    margin: 0 5%;
  }

}


