.fz-table {
  border: 0;
  padding-left: 8px;
  padding-right: 8px;
}

.fz-table td,th{
  padding-bottom: 3px;
  padding-top: 3px;
}

td.fz-column-center {
  text-align: center;
}

td.fz-column-right {
  text-align: right;
  padding-left: 12px;
  padding-right: 4px;
}

td.fz-column-left {
  padding-left: 4px;
  padding-right: 12px;
  text-align: left;
}

tr.fz-row-disabled {
  background-color: #f9eaec !important;
}

tr.fz-row-danger {
  background-color: #f9eaec !important;
}

tr.fz-row-anti-flash-white {
  background-color: #F2F2F2 !important;
}

#pageDropDown.btn-default {
  background: #007BFF;
  cursor: pointer;
	transition: filter 0.2s;
}

.btn.btn-default.btn-secondary.react-bs-table-search-clear-btn {
  display: flex;
  align-items: center;
  height: 2rem;
  background-image: none;
}