.group-or-rule-container {
  padding: 0 !important;
}

.card-title {
  margin: 0 !important;
}

.filterWrapper:not(:last-child) {
  padding-bottom: 20px;
  border-bottom: 1px solid #cecece;
}

.filterWrapper:not(:first-child) {
  padding-top: 20px;
}

.filterWrapper .filterHeader {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 15px;
}

.filterWrapper .filterHeader p {
  margin: 0 auto 0 0;
  font-size: 18px;
}

.emptyMessage {
  text-align: center;
  margin: 0;
}

