
.queue-details-fields label{
  font-weight: 700;
}

.rules-fields label, .events-fields label{
  font-size: 13px;
}

.rules-fields {
  background:#B1EDDC;
  padding: 10px;
  border-radius: 3px;
}

.events-fields {
  background:#D9F7FF;
  padding: 10px;
  border-radius: 3px;
}

.rules-buttons .row .col-4 {
  display: flex;
}

.flex-col {
  display: flex;
  align-items: center;
}

.flex-col .form-group {
  width: 100%;
}

.flex-col button {
  margin-top: 16px;
  margin-left: 10px;
  padding-left: 15px;
  padding-right: 15px;
  font-weight: 700;
}

.rules-button-label {
  width: 100%;
  background: #B1EDDC;
  text-align: center;
  font-weight: 400;
  font-size: 13px;
  padding-top: 10px;
  padding-bottom: 10px;
  border-radius: 3px;
}

.rules-buttons button {
  font-weight: 700;
  padding-left: 13px;
  padding-right: 13px;
  margin-left: 10px;
}

.queue-details-fields input[name='currentQueue.delay'] {
  margin-left: 10px;
}

.queue-details-fields .form-group:has(select[name='currentQueue.delaySelect']) {
  width: 75%;
}

.queue-details-fields .form-group:has(input[name='currentQueue.delay']) {
  width: 100%;
}

.queue-details-subtitle {
  font-weight: 700;
  font-size: 16px;
}