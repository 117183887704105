.App {
  text-align: center;
}

.App-logo {
  animation: App-logo-spin infinite 20s linear;
  height: 80px;
}

.App-header {
  background-color: #222;
  height: 150px;
  padding: 20px;
  color: white;
}

h1 {
  font-family: 'Roboto Slab', 'Georgia', serif;
}

h1.home-app-name {
  font-family: 'Roboto Slab', 'Georgia', serif;
  font-size: 60px;
  padding-bottom: -5px;
}

h4 {
  font-size: 110%;
  margin-bottom: 0px;
  margin-top: 0px;
  font-weight: bold;
}
.lbl-medium {
  font-size: 9pt;
  padding-bottom: 4px;
  padding-top: 4px;
  margin-right: 10px;
}
.panel-heading h3 {
  margin-left: 2px;
}

.panel-heading h4 {
  margin-left: 2px;
}

.App-intro {
  font-size: large;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.btn-form {
  margin-left: 10px;
  margin-right: 10px;
}

@media (max-width: 767px) {
  .btn {
    width: auto;
  }
}

table tbody tr td button.btn {
  margin-left: 0px;
}

.filter-state {
  float: right;
  padding-left: 10px;
}
.right-col {
  text-align: right;
}

.app-status {
  font-weight: bolder;
  font-size: 120%;
}

.container {
  width: 100%;
  padding-left: 5px;
  padding-right: 5px;
}
.modal-form {
  width: 80%;
}

.toolbar-right {
  float: right;
}
.panel-title p {
  margin-top: 0;
  margin-bottom: 0;
  font-size: 70%;
  color: #888;
}
.panel-title h4 {
  font-size: 12pt;
  margin-left: 0;
  margin-bottom: 0;
}
/*.panel-heading h3 {
  margin-top: 5px;
}*/
.query-details {
  color: #888;
  font-size: 90%;
}

.complementary-details {
  color: #888;
  font-size: 90%;
}

.tr-disabled-record {
  color: #888;
  font-style: italic;
  cursor: pointer;
}

.col-right {
  text-align: right;
}

.tr-enabled-record {
  cursor: pointer;
}

.branch-panel .panel-heading {
  cursor: pointer;
}

.disabled-link {
  pointer-events: none;
}

.btn-group .csv-input {
  float: left;
}

.btn-group .csv-input input {
  float: none;
}
.no-white-space {
  white-space: initial;
}

.flex-line {
  display: flex;
  flex-flow: row;
}
.flex-line > div {
  flex-grow: inherit;
}

.fullscreen-modal {
  display: none;
  position: fixed;
  z-index: 1100;
  /* padding-top: 100px; */
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: rgba(255, 255, 255, 1);
}

.fullscreen-modal-content {
  margin: auto;
  display: block;
  user-drag: none;
  user-select: none;
  -moz-user-select: none;
  -webkit-user-drag: none;
  -webkit-user-select: none;
  -ms-user-select: none;
}

.fullscreen-turn-img-btn {
  width: 50px;
  height: 50px;
  font-size: 20px;
  color: #3f51b5;
  position: fixed;
  top: 20px;
  left: 20px;
  z-index: 1;
}

.report-navbar {
  border-bottom: 0;
  padding-bottom: 0;
}

.adjusted-nav.nav-tabs {
  border-bottom: 0;
}

.btn .outline-success {
  border: 1px #28a754 solid
}

.iconList svg{
  height: 20px;
}

.iconField{
  position: absolute;
}
