.legend-table {
  font-size: 0.9em;
  font-family: sans-serif;
  min-width: 450px;
  color: white;
  box-shadow: 4px 3px 12px 3px rgba(0,0,0,0.4);
  margin-top: 5%;
  margin-bottom: 5%;
  margin-left: 10%;
  border-collapse: collapse;
  border-radius: 5px 5px 0 0;
  overflow: hidden;
  background-color: #5f646b;
}

.legend-table td{
  border-left: 2px solid #5f646b;
  border-right: 2px solid #5f646b;
  border-bottom: 2px solid #5f646b;
  text-align: center;
  color: #FFFFFF;
}

#legend-header{
  margin-left: 50%;
  text-align: center;
  background-color: #5f646b;
}







