#customBtn {
  display: flex;
  background: white;
  color: #444;
  border-radius: 1px;
  border: thin solid #ddd;
  box-shadow: 1px 1px 1px #ddd;
  white-space: nowrap;
}
#customBtn:hover {
  cursor: pointer;
}
div.label {
  font-family: serif;
  font-weight: normal;
}
div.icon {
  display: flex;
  justify-content: center;
  flex-grow: 1;
  width: 42px;
  height: 42px;
}
div.icon svg {
  align-self: center
}
div.buttonText {
  display: flex;
  flex-grow: 10;
  justify-content: center;
  vertical-align: middle;
}

div.buttonText .buttonTextWrapper {
  display: flex;
  align-self: center;
  font-family: 'Roboto', sans-serif;
  font-size: 1.5rem;
}