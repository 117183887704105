

.fz-desktop {
  background-image: linear-gradient(180deg,#68c2b6,#58b2a6);
}

.fz-desktop-container {
    display: inline-block;
  }

.fz-desktop .fz-desktop-header {
  display: inline-block;
}


.fz-pai {
  background-color: #68c2b6;
}

.fz-list-item {
  border-bottom: 3px solid #dedede !important;
}

.navbar {
  border: none !important;
}

.item-menu span {
 font-size: large;
}

.sub-item-menu span {
  display: flex;
  justify-content: center;
  font-size: large;
}

.fz-route-selected {
  background-color: #3f51b5 !important;
}