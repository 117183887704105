.splitline {
  display: flex;
  align-items: center;
}

.splitline-align-up {
  display: flex;
  align-items: flex-start;
}

.splitline-left {
  flex-shrink: 1;
  flex-grow: 1;
}

.splitline-right {
  flex-shrink: 0;
  flex-grow: 0;
}