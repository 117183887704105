.rule {
  padding: 10px;
}

.rule-status, button.rule-status {
  background: rgba(50,200,50,0.1)
}
.rule-status:hover, button.rule-status:hover {
  background: rgba(50,200,50,0.25)
}
.rule-campaigns, button.rule-campaigns {
  background: rgba(50, 200, 70, 0.25);
  width: 100%;
  color: black;
}
.rule-campaigns:hover, button.rule-campaigns:hover {
  background: rgba(50,200,50,0.1);
  color: black;
}
.rule-range, button.rule-range {
  background: rgba(200,50,50,0.1);
  width: 100%;
  color: black;
}
.rule-range:hover, button.rule-range:hover {
  background: rgba(200,50,50,0.25)
}
.rule-domain, button.rule-domain {
  margin: 0 !important;
  background: rgba(50,50,200,0.1);
  width: 100%;
  color: black;
}
.rule-domain:hover, button.rule-domain:hover {
  background: rgba(50,50,200,0.25)
}

.domain-multi-selector, .dms {

}
.dms--added {

}
.dms--added-item {
  padding: 10px;
  border-radius: 10px;
  border: solid 1px #ddd;
}