/* 
.teste {
  display: 'flex' !important;
  justify-content: flex-end;
} */



.desktopOpen {
  margin-left: 0 !important;
}


.mobileOpen {
  margin-left: 0 !important;
}


.MuiAppBar-colorPrimary {
 background-color: #5923DD
}

.makeStyles-content-5 {
  padding: 0 !important;
}


/*
.makeStyles-contentShift-6 {
  margin-left: calc(240px) !important;
} */
