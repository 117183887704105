.fz-auto-complete {

}

.fz-auto-complete--container {
  z-index: 3;
  display: block;
  width: 100%;
  height: 0;
  position: relative;
}

.fz-auto-complete--list {
    z-index: 2;
    background-color: #fff;
    overflow: hidden;
    position: relative;
    padding: 0;
    top: -5px;
    left: 0;
    right: 0;
    box-shadow: 1px 1px 1px 1px rgba(100,100,100,0.5);
    max-height: 200px;
    overflow-y: scroll;
    max-height: 200px;
    list-style-type: none;
}

.fz-auto-complete--item {
   padding: 5px 10px;
}

.fz-auto-complete--item:hover {
  background-color: #ccc;
}

.fz-auto-complete--item.isSelected{
  background-color: #ccc;
}
