.user-info {
  font-family: Roboto;
  font-size: 16px;
  font-weight: 400;
  line-height: 19px;
  letter-spacing: 0em;
  text-align: left;
}

.user-info b {
  font-weight: 700;
}

.user-info p {
  margin-top: 10px;
  margin-bottom: 10px;
}

.custom-split-line {
  height: 1px;
  border-bottom: 1px solid #DEDEDE;
  margin-top: 20px;
}